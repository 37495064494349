import React from 'react';
import PropTypes from 'prop-types';

import { IMG_LOCAL_PATH, CDN_URL, CDN_FOLDER, IMG_SIZES } from '../../config';

function Image(props) {
  const { alt, cdnManipulations, location, sizes, src, srcSet, ...otherProps } =
    props;

  if (location === 'cdn') {
    const srcSets = Image.createCDNSizes(src, cdnManipulations);

    return (
      <img
        alt={alt}
        src={srcSets.original}
        srcSet={srcSets.srcSet}
        sizes={sizes}
        {...otherProps}
      />
    );
  }

  if (location === 'local') {
    const srcSets = Image.createSizes(src);

    return (
      <picture>
        <source srcSet={srcSets.jpg} type="image/jpeg" sizes={sizes} />
        <source srcSet={srcSets.webp} type="image/webp" sizes={sizes} />
        <img alt={alt} src={srcSets.original} {...otherProps} />
      </picture>
    );
  }

  return (
    <img alt={alt} src={src} srcSet={srcSet} sizes={sizes} {...otherProps} />
  );
}

Image.createCDNSizes = (image, manipulations = []) => {
  // https://res.cloudinary.com/iteratus/image/upload/w_420/ar_1.5,c_fill,f_auto,q_auto:best/birgit-hoebel/190206_ordi_49_cszuic.jpg
  const all = [];
  const biggest = IMG_SIZES[IMG_SIZES.length - 1];

  const fixedManipulations = ['f_auto', 'q_auto:best'];
  const finalManipulations = [...fixedManipulations, ...manipulations].join(
    ',',
  );

  IMG_SIZES.forEach((imgSize) => {
    all.push(
      `${CDN_URL}/w_${imgSize}/${finalManipulations}/${CDN_FOLDER}/${image} ${imgSize}w`,
    );
  });

  return {
    srcSet: all.join(','),
    original: `${CDN_URL}/w_${biggest}/${finalManipulations}/${CDN_FOLDER}/${image}`,
  };
};

Image.createSizes = (image) => {
  const allJpg = [];
  const allWebP = [];

  IMG_SIZES.map((imgSize) => {
    allJpg.push(`${IMG_LOCAL_PATH}/${image}-${imgSize}.jpg ${imgSize}w`);
    allWebP.push(`${IMG_LOCAL_PATH}/${image}-${imgSize}.webp ${imgSize}w`);
    return imgSize;
  });

  return {
    jpg: allJpg.join(','),
    webp: allWebP.join(','),
    original: `${IMG_LOCAL_PATH}/${image}-1700.jpg`,
  };
};

Image.propTypes = {
  alt: PropTypes.string,
  cdnManipulations: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  location: PropTypes.oneOf(['cdn', 'local', 'web']),
  sizes: PropTypes.string,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
};

Image.defaultProps = {
  alt: '',
  cdnManipulations: [],
  location: 'cdn',
  sizes: null,
  srcSet: null,
};

export default Image;
